function stock_depotController($translate, $state, $scope, WS, $window) {
    'ngInject';
    const vm = this;
    vm.stocks = [];
    vm.allsocietes = {};
    vm.selectSoussocietes = function () {
        WS.get('soussocietes/selectSoussocietes').then(
            function (response) {
                vm.soussocietes = response.data;
                _.each(vm.soussocietes , element => {
                    vm.allsocietes[element.code] = element.nom;
                })

                if(vm.soussocietes.length == 1){
                    vm.soussociete_code = vm.soussocietes[0].code;
                    vm.getStockDepot();
                }
                
                $scope.$apply();
            },
            function (error) {
                console.log(error);
            });
    };

    vm.getStockDepot = function () {
        WS.getData('stock_depot/getStockDepot', {soussociete_code : vm.soussociete_code})
            .then(function (response) {

                vm.stocks_init = response.data.stocks;
                vm.produits = response.data.produits;
                vm.total_vente_ca = 0;
                _.each(vm.stocks_init, (element, index) => {
                    if(vm.produits[index]){
                        vm.total_vente_ca += element.quantite * vm.produits[index].prix_ttc;

                        vm.produits[index].quantite = element.quantite;
                        vm.produits[index].dlc = element.dlc;
                        vm.produits[index].casse = element.casse;
                        vm.produits[index].securite = element.securite;
                        vm.produits[index].reservation = element.reservation;
                        vm.produits[index].soussociete_code = vm.soussociete_code;
                        vm.produits[index].soussociete = vm.allsocietes[vm.soussociete_code];
                    }
                })
               vm.stock_depots=[];
               vm.stocks = [];
                _.each(vm.produits, stock => {
                    vm.stock_depots.push(stock);
                    console.log(stock)
                    vm.stocks[stock.id] = {};
                    vm.stocks[stock.id]['produit_code']=stock.produit_code;
                    vm.stocks[stock.id]['id']=stock.id;
                    vm.stocks[stock.id]['quantite']=stock.quantite ? stock.quantite : 0;
                    vm.stocks[stock.id]['colisage']=stock.colisage;
                    vm.stocks[stock.id]['dlc']=stock.dlc ? stock.dlc : 0;
                    vm.stocks[stock.id]['casse']=stock.casse ? stock.casse : 0;
                    vm.stocks[stock.id]['reservation']=stock.reservation ? stock.reservation : 0;
                    vm.stocks[stock.id]['securite']=stock.securite ? stock.securite : 0;
                    vm.stocks[stock.id]['prix_ttc']=stock.prix_ttc;
                    vm.stocks[stock.id]['prix_achat_ttc']=stock.prix_achat_ttc
                    vm.stocks[stock.id]['soussociete_code']=vm.soussociete_code;
                    vm.stocks[stock.id]['soussociete']=vm.allsocietes[vm.soussociete_code];

                })
                

                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.correctionStockDepot = function () {
        // var data = [];
        vm.latitude = null;
        vm.longitude = null;
        console.log(vm.stocks);
        if (vm.cause && vm.soussociete_code) {
            navigator.geolocation.getCurrentPosition(function (position) {
                vm.latitude = position.coords.latitude;
                vm.longitude = position.coords.longitude;
                console.log("s", vm.latitude, vm.longitude);

                WS.post('stock_depot/correctionStockDepot',
                    {
                        stocks: vm.stocks,
                        latitude: vm.latitude,
                        longitude: vm.longitude,
                        cause: vm.cause,
                        soussociete_code: vm.soussociete_code
                    }
                ).then(function (response) {
                    if (response.data.success == true) {
                        swal(
                            'Succès',
                            'Correction effectué avec succé',
                            'success'
                        ).then(function () {
                            $window.location.reload();
                        }).catch(function () {
                            $window.location.reload();
                        });
                    } else {
                        swal(
                            'Attention',
                            'Erreur de traitement des données',
                            'warning'
                        );
                    }
                    $scope.$apply();
                })
                    .then(null, function (error) {
                        console.log(error);
                    });

            });
            console.log('data', vm.stocks, vm.latitude, vm.longitude, vm.cause);

        } else {
            $('#cause').css('border', '2px solid red!important');
            swal(
                'Attention',
                'cause et distributeur obligatoires',
                'warning'
            );
        }
    };

    vm.verifyPassword = () => {
        WS.post('stock_depot/verifyPassword', { password: vm.current_password })
            .then(function (response) {
                if (response.status == 200) {
                    vm.verified_user = response.data.verified;
                    if (vm.verified_user) {
                        vm.edit = true;

                        $('#verification_modal').hide();
                        $('.modal-backdrop').remove();
                    } else {
                        swal(
                            $translate.instant('Oups!'),
                            $translate.instant("Vous n'êtes pas autorisé"),
                            'warning'
                        );
                    }
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.historiqueStockDepot = function (page = 1, per_page = 100) {
        WS.getData('stock_depot/historiqueStockDepot',
            {
                per_page, page,
                date_debut: vm.date_debut,
                date_fin: vm.date_fin,
                user_code: vm.user_code,
                produit_code: vm.produit_code,
            }
        ).then(function (response) {
            vm.historique_stock = response.data.data;
            vm.current_page = response.data.current_page;
            vm.previous = vm.current_page - 1;
            vm.next = vm.current_page + 1;
            vm.from = response.data.form;
            vm.last_page = response.data.last_page;
            vm.next_page_url = response.data.next_page_url;
            vm.per_page = Number(response.data.per_page);
            vm.prev_page_url = response.data.prev_page_url;
            vm.to = response.data.to;
            vm.total = response.data.total;
            vm.paginate = [];
            for (var i = 1; i <= vm.last_page; i++) {
                vm.paginate.push(i);
            }
            $scope.$apply();
        })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.checkpaginate = function(current, page, lastpage) {
        if (page > current + 3 || page < current - 3) {
            return false;
        } else {
            return true;
        }
    }

    vm.selectProduits = function () {
        WS.get('produits/selectProduit').then(
            function (response) {
                vm.selectproduits = response.data;
                $scope.$apply();
            },
            function (error) {
                console.log(error);
            });
    }

    vm.selectUsersFRomSTK = function () {
        WS.get('stock_depot/selectUsersFRomSTK')
            .then(function (response) {
                if (response.status == 200) {
                    vm.selectusers = response.data;

                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };



    

}
export default {
    name: 'stock_depotController',
    fn: stock_depotController
};
