function quotaController($http, DatatableLang, $state, $scope, WS, $window, $translate, $uibModal, $locale) {
    'ngInject';
    $locale.NUMBER_FORMATS.GROUP_SEP = "";
    $locale.NUMBER_FORMATS.DECIMAL_SEP = ".";



    const vm = this;
    //vm.chargement = {};
    vm.produits = {};
    vm.chargementu = {};
    vm.camion = {};
    vm.totalsortiecamion = {};

    vm.csvfile = {};
    vm.auth_chargement = "1";
    vm.chargement_apply_filter = { chargement: vm.auth_chargement };

    vm.applyfilter = function () {
        if (vm.auth_chargement == "0") {
            vm.chargement_apply_filter = '';
        } else {
            vm.chargement_apply_filter = { chargement: vm.auth_chargement };
        }
    };

    WS.getScache('societes')
        .then(function (response) {
            vm.societes = response.data[0];
            var contrat_code = vm.societes.contrat;
            /*console.log(response);
            console.log(contrat_code);*/
            $http({
                method: 'GET',
                url: `https://api.sav.priorityexpeditions.com/api/v1/license/${contrat_code}`,
                cache: false
            }).then(function successCallback(response) {
                vm.global_gestion_stock = response.data.gestion_stock;
            }, function errorCallback(response) {
            });
            $scope.$apply();

        })
        .then(null, function (error) {
            console.log(error);
        });





    vm.getFirstSociete = function () {
        WS.get('societes/getFirstSociete').then(
            function (response) {
                vm.societe_global = response.data;
                $scope.$apply();
            },
            function (error) {
                console.log('error : ->', error);
            }
        );
    };
    vm.getFirstSousSociete = function () {
        WS.get('soussocietes/first')
            .then(function (response) {
                vm.societe = response.data;

                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.getCommercialByEquipement = function (code, liste) {
        if (!liste) return;
        for (var i = 0; i < liste.length; i++) {
            if (liste[i]['code'] == code) {
                return liste[i];
            }
        }
    }




    vm.store = function (chargement) {
        vm.show_loader = true;
        vm.btn_save = true;
        var ch = {};
        ch.produits = [];
        var charg = {};
        var hors_stk = 0;
        if (vm.multi_camion == false) {

            _.each(chargement.ch, (value, key) => {
                if (
                    key != 'numero' && key != 'commercial' && key != 'colisage' && key != 'type' &&
                    key != 'envoi' && key != 'retour_hier' && key != 'date' && key != 'tournee_code'
                    && key != 'code_jour'
                ) {
                    // console.log(key);
                    // console.log(value.retour_hier_p, value.quantite);
                    if (vm.societe_global.authorization_stock == 1) {
                        if (chargement.ch.retour_hier) {
                            if (chargement.ch.colisage == true) {
                                if (
                                    (value.retour_hier_p) +
                                    (value.quantite * value.colisage) > value.stk_quantite || value.quantite < 0
                                ) {
                                    hors_stk = hors_stk + 1;
                                }
                            } else {
                                if (value.retour_hier_p + value.quantite > value.stk_quantite || value.quantite < 0) {
                                    hors_stk = hors_stk + 1;
                                }
                            }
                        } else {
                            if (chargement.ch.colisage == true) {
                                if (value.quantite * value.colisage > value.stk_quantite || value.quantite < 0) {
                                    hors_stk = hors_stk + 1;
                                }
                            } else {
                                if (value.quantite > value.stk_quantite || value.quantite < 0) {
                                    hors_stk = hors_stk + 1;
                                }
                            }
                        }


                    }

                    if (value.quantite != undefined && typeof value.quantite == "number" && value.quantite != null && value.quantite != NaN) {
                        ch.produits[key] = value;

                    }

                } else if (key == 'numero' || key == 'commercial' || key == 'colisage' ||
                    key == 'type' || key == 'envoi' || key == 'retour_hier' ||
                    key == 'date' || key == 'tournee_code' || key == 'code_jour') {
                    ch[key] = value;
                }
            });
            charg.ch = ch;
            charg.multi_camion = vm.multi_camion;
            charg.ch.date = chargement.ch.date;
            charg.ch.produits = _.reject(ch.produits, _.isNull);
            charg.ch.produits = _.without(charg.ch.produits, undefined);
            console.log(charg);
            if (charg.ch.produits.length > 0) {
                console.log("ch store quota", charg);
                if (hors_stk == 0) {
                    WS.post('quota/storeforOne', charg)
                        .then(function (response) {
                            swal(
                                'Succès',
                                'chargement ajoutée avec Succès',
                                'success'
                            ).then(function () {
                                $window.location.reload();
                            }).catch(function (error) {
                                $window.location.reload();
                            });
                            vm.btn_save = true;
                            $scope.$apply();
                        })
                        .then(null, function (error) {
                            swal(
                                'Attention!',
                                'Erreur d\'insertion',
                                'warning'
                            )
                            vm.btn_save = true;
                        });
                } else {
                    swal(
                        'Attention!',
                        'veuillez bien vérifier les quantités supérieures à stock ou les quantite négative',
                        'warning'
                    );
                    vm.btn_save = false;
                    //$scope.$apply();
                }
            } else {
                swal(
                    'Attention!',
                    'veuillez bien charger au moins un produit',
                    'warning'
                );
                vm.btn_save = false;

            }

        }
        // else {
        //     //console.log(vm.chargement);
        //     var panel = [];
        //     _.each(vm.chargement.inverse_multi_camion, (prod, predkey) => {
        //         //prod.total_chargement = 70;
        //         if (prod.total_chargement != undefined && prod.total_chargement != 0) {

        //             if (vm.societe.authorization_stock == 1) {
        //                 if (chargement.ch.colisage == true) {
        //                     if (prod.total_chargement * prod.colisage > prod.stk_quantite) {
        //                         hors_stk = hors_stk + 1;
        //                     }
        //                 } else {
        //                     if (prod.total_chargement > prod.stk_quantite) {
        //                         hors_stk = hors_stk + 1;
        //                     }
        //                 }
        //             }
        //             if (hors_stk == 0) {
        //                 _.each(prod, (element, depot_key) => {
        //                     if (typeof element == 'object') {
        //                         if (element.quantite != 0) {
        //                             if (panel[depot_key] == undefined) {
        //                                 panel[depot_key] = {};
        //                                 panel[depot_key]['produits'] = [];
        //                                 panel[depot_key]['depot_code'] = element.depot_code;
        //                                 panel[depot_key]['commercial_code'] = element.user_code;
        //                             }
        //                             if (chargement.ch.colisage == true) {
        //                                 var quantite = element.quantite * element.colisage;
        //                             } else {
        //                                 var quantite = element.quantite;
        //                             }
        //                             panel[depot_key]['produits'].push({
        //                                 produit_code: element.produit_code,
        //                                 quantite: quantite,
        //                                 prix_ttc: element.prix_ttc,
        //                                 prix_achat_ttc: element.prix_achat_ttc
        //                             });
        //                         }
        //                     }
        //                 });
        //             }
        //         }
        //         //console.log("panel", panel);

        //     });

        //     if (hors_stk == 0) {
        //         panel = _.omit(panel, _.isEmpty);//remove all empty keys from panel array

        //         charg.chargements = panel;
        //         charg.multi_camion = vm.multi_camion;
        //         charg.type = vm.chargement.ch.type;
        //         charg.date = chargement.ch.date;
        //         console.log(charg);
        //         WS.post('mouvements/chargementStoreMultiCamion', charg)
        //             .then(function (response) {
        //                 swal(
        //                     'Succès',
        //                     'chargement ajoutée avec Succès',
        //                     'success'
        //                 ).then(function () {
        //                     $window.location.reload();
        //                 }).catch(function (error) {
        //                     $window.location.reload();
        //                 });
        //                 vm.btn_save = true;
        //                 $scope.$apply();
        //             })
        //             .then(null, function (error) {
        //                 console.log(error);
        //             });

        //     } else {
        //         swal(
        //             'Attention!',
        //             'veuillez bien vérifier les quantités supérieures à stock',
        //             'warning'
        //         );
        //         vm.btn_save = false;
        //     }
        // }

        vm.show_loader = false;

    }

    vm.storeChargementOneDepot = function (depot_code, user_code) {
        var data = [];
        _.each(vm.produits, (prod, index) => {
            var quantite = 0;
            if (vm.chargement.multi_camion[user_code][prod.id] != undefined) {

                if (
                    vm.chargement.multi_camion[user_code][prod.id]['quantite'] != undefined &&
                    typeof vm.chargement.multi_camion[user_code][prod.id]['quantite'] == 'number'
                ) {
                    if (vm.chargement.ch.colisage) {
                        quantite = vm.chargement.multi_camion[user_code][prod.id]['quantite'] * prod.colisage;
                    } else {
                        quantite = vm.chargement.multi_camion[user_code][prod.id]['quantite'];
                    }
                    data.push({
                        user_code: user_code,
                        depot_code: depot_code,
                        produit_code: prod.code,
                        quantite: quantite,
                        prix_achat_ttc: prod.prix_achat_ttc,
                        prix_ttc: prod.prix_ttc,
                    });
                }
            }
        });
        console.log('storeChargementOneDepot');
        console.log(data);
        if (data.length > 0) {
            WS.post('quota/storeQuota', { data: data })
                .then(function (response) {
                    if (response.data.success == true) {
                        swal(
                            'Succès',
                            'Quota ajouter avec succés',
                            'success'
                        );
                        // $(`#btn_save_${depot_code}`).removeClass("btn-success");
                        // $(`#btn_save_${depot_code}`).addClass("btn-default");
                        // $(`#btn_save_${depot_code}`).prop("disabled", true);

                        // _.each(vm.produits, (prod, index) => {
                        //     $(`#quantite_${depot_code}_${prod.code}`).prop("disabled", true);
                        // });
                    }
                    $scope.$apply();

                })
                .then(null, function (error) {
                    console.log(error);
                });
        } else {
            swal(
                'Attention!',
                'veuillez bien vérifier les quantités',
                'warning'
            );
        }

    };


    vm.clearform = function () {
        vm.loading = true;
        vm.findgamme = null;
        vm.findfamille = null;
        vm.findsousfamille = null;
        vm.getproduits();
    }
    vm.searchBy = function (gamme, famille, sousfamille) {
        vm.loading = true;

        var find = {};
        var i = 0;
        if (gamme != '' && gamme != null) {
            find['gamme'] = gamme;
            i++;
        }
        if (famille != '' && famille != null) {
            find['famille'] = famille;
            i++;
        }
        if (sousfamille != '' && sousfamille != null) {
            find['sousfamille'] = sousfamille;
            i++;
        }
        if (i == 0) { vm.loading = false; return; }

        WS.post('mouvements/searchByColumn', find)
            .then(function (response) {
                vm.produits = response.data.produits;
                vm.chargement.ch.numero = 'CH-' + response.data.countch;
                vm.loading = false;
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    // $(document).keydown(function (e) {
    //     console.log('key code is: ' + e.which + ' ' + (e.ctrlKey ? 'Ctrl' : '') + ' ' +
    //         (e.shiftKey ? 'Shift' : '') + ' ' + (e.altKey ? 'Alt' : ''));
    //         if(e.which == 70 && e.ctrlKey){
    //             e.preventDefault();
    //             $('#searchbox').focus();
    //         }
    // });

    vm.findAllQuota = () => {
        WS.getData('quota/findAllQuota').then(
            (response) => {
                vm.totalsortiecamion = {};
                vm.allchargementsTotal = 0;
                vm.existsQuota = response.data;

                //reinitialisation
                _.each(vm.existsQuota, (quota, index) => {
                    console.log(index, quota.user_code, quota.produit_code, quota);

                    if (vm.chargement.multi_camion[quota.user_code] == undefined) {
                        vm.chargement.multi_camion[quota.user_code] = {};
                    }
                    if (vm.chargement.multi_camion[quota.user_code][quota.produit_id]) {
                        vm.chargement.multi_camion[quota.user_code][quota.produit_id]['quantite'] = '';
                        vm.chargement['inverse_multi_camion'][quota.produit_id][quota.user_code]['quantite'] = '';
                        vm.chargement.ch[quota.produit_id]['quantite'] = '';
                    }
                    vm.totalsortiecamion[quota.produit_id] = 0;
                });
                // nouveau traitement
                if (vm.multi_camion) {
                    _.each(vm.existsQuota, quota => {
                        if (vm.chargement.multi_camion[quota.user_code] == undefined) {
                            vm.chargement.multi_camion[quota.user_code] = {};
                        }
                        if (vm.chargement.multi_camion[quota.user_code][quota.produit_id]) {
                            vm.chargement.multi_camion[quota.user_code][quota.produit_id]['quantite'] = quota.quantite;
                            vm.chargement['inverse_multi_camion'][quota.produit_id][quota.user_code]['quantite'] = quota.quantite;
                        }
                        vm.totalsortiecamion[quota.produit_id] = (vm.totalsortiecamion[quota.produit_id] == undefined) ? 0 : vm.totalsortiecamion[quota.produit_id];
                        vm.totalsortiecamion[quota.produit_id] += (vm.totalsortiecamion[quota.produit_id] == undefined) ? 0 : quota.quantite;
                        vm.allchargementsTotal += quota.quantite;
                    });
                } else {
                    _.each(vm.existsQuota, quota => {
                        if (quota.user_code == vm.chargement.ch.commercial) {
                            vm.chargement.ch[quota.produit_id]['quantite'] = quota.quantite;
                            vm.totalsortiecamion[quota.produit_id] = (vm.totalsortiecamion[quota.produit_id] == undefined) ? 0 : vm.totalsortiecamion[quota.produit_id];
                            vm.totalsortiecamion[quota.produit_id] += (vm.totalsortiecamion[quota.produit_id] == undefined) ? 0 : quota.quantite;
                        }
                    });
                }
                vm.total_Casier_chargement();
                $scope.$apply();

            },
            (error) => {
                console.log(error);
            }
        )
    };

    vm.truncateQuota = () => {
        swal({
            title: 'Vider Quota',
            text: "Voulez vous vraiment supprimer tout les donnees de quota!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui',
            cancelButtonText: 'Non!',
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger',
            buttonsStyling: false
        }).then(function () {
            WS.post('quota/truncateQuota').then(
                function (response) {
                    if (response.data.success) {
                        swal(
                            'Supprimé!',
                            'quota supprimé avec Succès.',
                            'success'
                        ).then(function () {
                            $window.location.reload();
                        });
                    }

                },
                function () {
                    swal(
                        'Attention!',
                        'Erreur de suppression',
                        'warning'
                    );
                }
            );
        });
    }

    vm.getproduits = function () {

        WS.getData('mouvements/names')
            .then(function (response) {
                // vm.chargement = {};
                // vm.chargement.ch = {};
                vm.chargement.ch.numero = 'CH-' + response.data.countch.toString();
                vm.produits = response.data.produits;
                vm.loading = false;

                _.each(vm.produits, (prod, indprod) => {
                    vm.chargement['ch'][prod.id] = {};
                    vm.chargement['ch'][prod.id]['retour_hier_p'] = 0;
                    vm.chargement['ch'][prod.id]['quantite'] = '';
                    vm.chargement['ch'][prod.id]['produit_code'] = prod.code;
                    vm.chargement['ch'][prod.id]['colisage'] = prod.colisage;
                    vm.chargement['ch'][prod.id]['prix_ttc'] = prod.prix_ttc;
                    vm.chargement['ch'][prod.id]['prix_achat_ttc'] = prod.prix_achat_ttc;
                    vm.chargement['ch'][prod.id]['hors_box'] = prod.hors_box;
                    vm.chargement['ch'][prod.id]['stk_quantite'] = prod.stk_quantite;
                });


                /* _.each(vm.selectdepots, (dep, inddep) => {
                     _.each(vm.produits, (prod, indprod) => {
                         $scope.$watch(vm.chargement.multi_camion[dep.code][prod.id], function(newvalue) {
                             console.log('watched' , newvalue);
                             vm.totalsortiecamion[prod.id] = vm.chargement.multi_camion[dep.code].reduce((s, f) => {
                                 return Number(s) + Number(f.quantite);              
                             }, 0);
                         }, true); 
                     });
                 });*/
                $scope.$apply();
                vm.findAllQuota();
                vm.total_Casier_chargement();



            })
            .then(null, function (error) {
                console.log(error);
            });
    };




    vm.getCommercials = function () {
        WS.getScache('quota/selectUsersPreCommande')
            .then(function (response) {
                vm.commercials = response.data;
                vm.selectdepots = response.data;
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.getGammes = function () {
        WS.get('gammes')
            .then(function (response) {
                vm.gammes = response.data;
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.getMarque = function () {
        WS.get('marques')
            .then(function (response) {
                vm.marques = response.data;
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.getAllproduits = function () {
        WS.get('produits/names')
            .then(function (response) {
                vm.produitsnames = response.data;
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.getFamilles = function () {
        WS.get('familles')
            .then(function (response) {
                vm.familles = response.data;
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.getSousfamilles = function () {
        WS.get('sousfamilles')
            .then(function (response) {
                vm.sousfamilles = response.data;
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.importChargementStoreCSV = function (numero, commercial, type) {
        vm.csvfile.filetxt = vm.files[0];
        vm.csvfile.numero = numero;
        vm.csvfile.commercial = commercial;
        vm.csvfile.type = type;
        vm.csvfile.verif_import = vm.verif_import;
        vm.csvfile.numero_import = vm.numero_import;
        vm.csvfile.check_gestion_stock = 1;//vm.global_gestion_stock;
        //console.log(vm.csvfile);
        WS.postwithimg('mouvements/importChargementStoreCSV', vm.csvfile, "POST")
            .then(function (response) {
                if (response.data.success == true) {
                    swal(
                        'Succès',
                        response.data.message,
                        'success'
                    ).then(function () {
                        $window.location.reload();
                    });
                } else {
                    swal(
                        'Warning',
                        response.data.message,
                        'warning'
                    );
                }
                if (response.data.error == true) {
                    swal(
                        'Warning',
                        '',
                        'warning'
                    );
                }
            }, function (error) {
                console.log(error);
            });
    };

    $scope.moveCSV = function (element) {
        var reader = new FileReader();
        reader.onload = function (event) {
            vm.csvfile.filetxt = event.target.result;
            $scope.$apply(function () {
                vm.files = element.files;
            });
        }
        reader.readAsDataURL(element.files[0]);
    };


    /**
     * init variables
     */

    vm.getUserInfo = function (user_code) {
        WS.getScache('users/getUserInfo/' + user_code).then(
            (response) => {
                vm.userinfo = response.data;
                $scope.$apply();
            },
            (error) => {
                console.log(error)
            }
        )
    };


    vm.selectUsers = function () {
        WS.get('users/selectUsers', vm.access)
            .then(function (response) {
                if (response.status == 200) {
                    vm.selectusers = response.data;

                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };



    vm.printElement = function (header, body, footer) {

        var mywindow = window.open('', 'PRINT');

        mywindow.document.write(`<html>
                                    <head>
                                    <title> ${document.title} </title>
                                    <style>
                                        .noprint{display: none;}.text-center{text-align:center}
                                        th{border:1px solid #ddd!important;}
                                        td{border:1px solid #ddd!important;}
                                    </style>
                                `);
        mywindow.document.write('</head><body >');
        mywindow.document.write(document.getElementById(header).innerHTML);
        mywindow.document.write(document.getElementById(body).innerHTML);
        mywindow.document.write(document.getElementById(footer).innerHTML);
        mywindow.document.write('</body></html>');
        mywindow.document.close(); // necessary for IE >= 10
        mywindow.focus(); // necessary for IE >= 10*/

        mywindow.print();
        mywindow.close();

        return true;
    }







    vm.selectTournees = function (user_code) {
        WS.get('tournees/selectTournees').then(
            (response) => {
                vm.selecttournees = response.data;
                $scope.$apply();
            },
            (error) => {
                console.log(error)
            }
        )
    }

    vm.init_codejourUndefined = function () {
        if (vm.chargement['ch']['code_jour'] == '') {
            vm.chargement['ch']['code_jour'] = undefined;
            vm.filter_tournee_code = { code_jour: vm.chargement['ch']['code_jour'] };
        } else if (vm.chargement['ch']['code_jour'] == 'Universel') {
            vm.filter_tournee_code = { code: vm.chargement['ch']['code_jour'] };
        } else {
            vm.filter_tournee_code = { code_jour: vm.chargement['ch']['code_jour'] };
        }
    }



    vm.getListChargementType = function () {
        WS.get('chargements/getListChargementType').then(
            (response) => {
                vm.listchargement_type = response.data;
                $scope.$apply();
            },
            (error) => {
                console.log(error)
            }
        );
    };

    vm.appendQuantiteInChargement = function (tournee_code) {
        /**
         * init box;
         */
        vm.c_box = true;
        vm.c_box_title = "Convertir en Box";
        vm.chargement['ch']['colisage'] = false;

        _.each(vm.chargement.ch, (value, key) => {
            if (value instanceof Object) {
                vm.chargement['ch'][key]['quantite'] = undefined;
            }
        });
        WS.get('chargements/getOneChargementType/' + tournee_code).then(
            (response) => {
                _.each(vm.chargement.ch, (value, key) => {
                    if (value instanceof Object) {
                        var i = 0;
                        _.each(response.data, (chargementtype, keycht) => {
                            if (chargementtype.produit_code == value.produit_code) {
                                vm.chargement['ch'][key]['quantite'] = Number(chargementtype.quantite);
                                i++;
                            }

                        });

                        if (i == 0) {
                            vm.chargement['ch'][key]['quantite'] = 0;
                        }
                    }
                });
                vm.total_Casier_chargement();
                $scope.$apply();
            },
            (error) => {
                console.log(error)
            }
        );
    }


    vm.appendQuantiteInChargementType = function (tournee_code) {
        /**
         * init box;
         */

        _.each(vm.chargement.ch.produits, (value, key) => {
            if (value instanceof Object) {
                vm.chargement['ch']['produits'][key]['quantite'] = undefined;
            }
        });
        if (tournee_code != '' && tournee_code != undefined) {
            WS.get('chargements/getOneChargementType/' + tournee_code).then(
                (response) => {
                    if (response.data.length > 0) {
                        _.each(vm.chargement.ch.produits, (value, key) => {
                            if (value instanceof Object) {
                                _.each(response.data, (chargementtype, keycht) => {
                                    if (chargementtype.produit_code == value.produit_code) {
                                        vm.chargement['ch']['produits'][key]['quantite'] = Number(chargementtype.quantite);
                                        //console.log(vm.chargement['ch']['produits'][key]['quantite'])

                                    }
                                });
                            }
                        });
                    }
                    $scope.$apply();
                },
                (error) => {
                    console.log(error)
                }
            );
        }
    }

    vm.convertirEnBox = function (chargement) {
        vm.show_loader = true;
        if (vm.multi_camion == false) {
            if (vm.c_box == true) {
                _.each(chargement.ch, (value, key) => {
                    if (value instanceof Object) {
                        vm.chargement['ch'][key]['quantite'] = Number(vm.chargement['ch'][key]['quantite']) / Number(value.colisage);
                    }

                });
                // cocher colisage
            } else {
                _.each(chargement.ch, (value, key) => {
                    if (value instanceof Object) {
                        vm.chargement['ch'][key]['quantite'] = Number(vm.chargement['ch'][key]['quantite']) * Number(value.colisage);
                    }

                });
                // decocher colisage
            }
            if (vm.c_box == false) { vm.c_box_title = "Convertir en Box"; } else { vm.c_box_title = "Convertir en piéce"; }
            vm.chargement['ch']['colisage'] = vm.c_box;
            vm.c_box = !vm.c_box;
        } else {
            ///////////////////////////// Multi camion \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
            //console.log(vm.chargement.multi_camion);
            if (vm.c_box == true) {
                _.each(vm.chargement.multi_camion, (depots, key_dep) => {
                    _.each(depots, (prod, idprod) => {
                        if (vm.chargement.multi_camion[key_dep][idprod]['quantite'] != undefined &&
                            vm.chargement.multi_camion[key_dep][idprod]['quantite'] != null &&
                            vm.chargement.multi_camion[key_dep][idprod]['quantite'] != ''
                        ) {
                            vm.chargement.multi_camion[key_dep][idprod]['quantite'] = Number(vm.chargement.multi_camion[key_dep][idprod]['quantite']) /
                                Number(vm.chargement.multi_camion[key_dep][idprod]['colisage']);
                            //vm.chargement.multi_camion[key_dep][idprod]['quantite'] = vm.chargement.multi_camion[key_dep][idprod]['quantite'].toString().replace(/,/g, '.'); 
                            //vm.chargement.multi_camion[key_dep][idprod]['quantite'] = Number(vm.chargement.multi_camion[key_dep][idprod]['quantite']); 
                        }
                    });
                });
                // cocher colisage
            } else {
                _.each(vm.chargement.multi_camion, (depots, key_dep) => {
                    _.each(depots, (prod, idprod) => {
                        if (vm.chargement.multi_camion[key_dep][idprod]['quantite'] != undefined &&
                            vm.chargement.multi_camion[key_dep][idprod]['quantite'] != null &&
                            vm.chargement.multi_camion[key_dep][idprod]['quantite'] != ''
                        ) {
                            vm.chargement.multi_camion[key_dep][idprod]['quantite'] = Number(vm.chargement.multi_camion[key_dep][idprod]['quantite']) *
                                Number(vm.chargement.multi_camion[key_dep][idprod]['colisage']);
                        }
                    });
                });
                // decocher colisage
            }
            if (vm.c_box == false) { vm.c_box_title = "Convertir en Box"; } else { vm.c_box_title = "Convertir en piéce"; }
            vm.chargement['ch']['colisage'] = vm.c_box;
            vm.c_box = !vm.c_box;
        }
        vm.show_loader = false;



    };

    /**
     * export axcel bl+avoir stat-chargements
     */
    vm.exportExcel = function (withavoir = null) {
        var bldate = {
            date_debut: vm.stat_date_debut,
            date_fin: vm.stat_date_fin,
            commercial: vm.stat_commercial
        }
        if (bldate == undefined || bldate == {}) {
            swal($translate.instant('Error'), $translate.instant('Veuillez bien remplir les deux champs date'), 'error');
            return;
        } else if (bldate.date_debut == undefined) {
            swal($translate.instant('Error'), $translate.instant('Veuillez bien remplir le champ date debut'), 'error');
            return;
        } else if (bldate.date_fin == undefined) {
            swal($translate.instant('Error'), $translate.instant('Veuillez bien remplir le champ date fin'), 'error');
            return;
        } else if (bldate.commercial == undefined || bldate.commercial == '') {
            swal($translate.instant('Error'), $translate.instant('Veuillez bien remplir le champ commercial'), 'error');
            return;
        } else {
            if (withavoir) {
                //withavoir = 'avoir';
                var url = 'bl/getByDateExport/' + bldate.date_debut + '/' + bldate.date_fin + '/bl/avoir';
                if (bldate.commercial && bldate.commercial != "") {
                    var url = url + '/' + bldate.commercial;
                }
            } else {
                var url = 'bl/getByDateExport/' + bldate.date_debut + '/' + bldate.date_fin + '/bl';
                if (bldate.commercial && bldate.commercial != "") {
                    var url = url + '/null/' + bldate.commercial;
                }
            }

            WS.get(url)
                .then(function (response) {
                    if (response.data.length > 0) {
                        var date_debut = response.data[response.data.length - 1].date_creation;
                        var date_fin = response.data[0].date_creation;
                        var filename = 'Bl+avoir' + date_debut + '-_-_-' + date_fin;
                    } else {
                        var filename = 'Bl+avoir';

                    }
                    response.data.forEach(function (element, index) {
                        response.data[index].type = $translate.instant(element.type);
                    });

                    var stylecsv = {
                        sheetid: filename,
                        headers: true,
                        caption: {
                            title: filename,
                            style: 'font-size: 50px; color:blue;' // Sorry, styles do not works
                        },
                        //style:'background:#fff',
                        column: {
                            style: 'font-size:20px;background:#d1d1d1;'
                        },
                        columns: [
                            { columnid: 'code', title: $translate.instant('Code') },
                            { columnid: 'produit', title: $translate.instant('Produit') },
                            { columnid: 'marque', title: $translate.instant('Marque') },
                            { columnid: 'gamme', title: $translate.instant('Gamme') },
                            { columnid: 'famille', title: $translate.instant('Famille') },
                            { columnid: 'sousfamille', title: $translate.instant('Sous Famille') },
                            { columnid: 'entetecommercial_code', title: $translate.instant('BL_N°') },
                            { columnid: 'quantite', title: $translate.instant('Quantite') },
                            { columnid: 'pu_ht', title: $translate.instant('Prix HT') },
                            { columnid: 'pu_ttc', title: $translate.instant('Prix TTC') },
                            { columnid: 'p_tva', title: $translate.instant('tva') },
                            { columnid: 'taux_tva', title: $translate.instant('Taux Tva') },
                            { columnid: 'total', title: $translate.instant('Total') },
                            { columnid: 'total_net', title: $translate.instant('Total Net') },
                            { columnid: 'date_creation', title: $translate.instant('date') },
                            { columnid: 'taux_remise', title: $translate.instant('Taux de Remise') },
                            { columnid: 'remise', title: $translate.instant('Remise') },
                            { columnid: 'type', title: $translate.instant('Type') },
                            { columnid: 'commercial', title: $translate.instant('Commercial') },
                            { columnid: 'client', title: $translate.instant('Client') },
                            { columnid: 'magasin', title: $translate.instant('Magasin') },
                            { columnid: 'routing_code', title: $translate.instant('Route') },
                            { columnid: 'localite', title: $translate.instant('Localite') },
                            { columnid: 'region', title: $translate.instant('Region') },
                            { columnid: 'gouvernorat', title: $translate.instant('Gouvernorat') },
                            { columnid: 'secteur', title: $translate.instant('Secteur') },
                            { columnid: 'zone', title: $translate.instant('Zone') }
                        ],
                        row: {
                            style: function (sheet, row, rowidx) {
                                return 'text-align:center;background:' + (rowidx % 2 ? '#fff' : '#f1f1f1') + ';height:100px!important;';
                            }
                        }
                    };
                    alasql('SELECT * INTO XLS("' + filename + '.xls",?) FROM ?', [stylecsv, response.data]);
                    //alasql('SELECT * INTO CSV("' + filename + '.csv",?) FROM ?', [stylecsv, response.data]);
                })
                .then(null, function (error) {
                    console.log(error);
                });
        }
    };

    vm.printElementChargement = function PrintElem(elem) {
        var mywindow = window.open('', 'PRINT');

        mywindow.document.write(`<html>
                                    <head>
                                    <title> Bon de Chargement </title>
                                    <style>.noprint{display: none;}.text-center{text-align:center}</style>
                                    <style>
                                        th.text-center{border:1px solid #ddd!important; font-size:12px!important;}
                                        td.text-center{border:1px solid #ddd!important; font-size:12px!important;}
                                        h4,h5{margin: 4px;}
                                    </style>
                                `);
        mywindow.document.write('</head><body>');
        mywindow.document.write(document.getElementById(elem).innerHTML);
        mywindow.document.write('</body></html>');

        mywindow.document.close(); // necessary for IE >= 10
        mywindow.focus(); // necessary for IE >= 10*/

        mywindow.print();
        mywindow.close();

        return true;
    };








    vm.printChargementType = function PrintElem(elem) {
        var mywindow = window.open('', 'PRINT');

        mywindow.document.write(`<html>
                                    <head>
                                    <title> Bon de Chargement Type </title>
                                    <style>.noprint{display: none;}.text-center{text-align:center}</style>
                                    <style>
                                        th{border:1px solid #ddd!important; font-size:13px;}
                                        td{border:1px solid #ddd!important; font-size:13px;}
                                        h4,h5{margin: 4px;}
                                    </style>
                                `);
        mywindow.document.write('</head><body >');
        //mywindow.document.write('<h1>' + document.title  + '</h1>');
        mywindow.document.write(document.getElementById(elem).innerHTML);
        mywindow.document.write('</body></html>');

        mywindow.document.close(); // necessary for IE >= 10
        mywindow.focus(); // necessary for IE >= 10*/

        mywindow.print();
        mywindow.close();

        return true;
    };


    vm.printChargement = function (elem) {
        var mywindow = window.open('', 'PRINT');

        mywindow.document.write(`<html>
                                    <head>
                                    <title> Bon de Chargement </title>
                                    <style>.noprint{display: none;}.text-center{text-align:center}</style>
                                    <style>
                                        th{border:1px solid #ddd!important; font-size:10px;}
                                        td{border:1px solid #ddd!important; font-size:10px;}
                                        h4,h5{margin: 4px;}
                                        
                                    </style>
                                `);
        mywindow.document.write('</head><body >');
        //mywindow.document.write('<h1>' + document.title  + '</h1>');
        mywindow.document.write(document.getElementById(elem).innerHTML);
        mywindow.document.write('</body></html>');

        mywindow.document.close(); // necessary for IE >= 10
        mywindow.focus(); // necessary for IE >= 10*/

        mywindow.print();
        mywindow.close();

        return true;
    };


    vm.exporttableHtml = function (tableId) {
        var uri = 'data:application/vnd.ms-excel;base64,',
            template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
            base64 = function (s) { return $window.btoa(unescape(encodeURIComponent(s))); },
            format = function (s, c) { return s.replace(/{(\w+)}/g, function (m, p) { return c[p]; }) };

        var table = $(tableId),
            ctx = { worksheet: 'WireWorkbenchDataExport', table: table.html() },
            href = uri + base64(format(template, ctx));

        //
        var download_link = document.createElement('a');
        download_link.href = href;
        var title = "";
        if (vm.stat_date_debut && vm.stat_date_fin && vm.userinfo.user) {
            title += vm.stat_date_debut + '_||_' + vm.stat_date_fin + '_||_' + vm.userinfo.user;
        } else if (vm.stat_date_debut && vm.stat_date_fin && !vm.userinfo.user) {
            title += vm.stat_date_debut + '_||_' + vm.stat_date_fin + '_||_ALLCommercials';
        } else {
            title = new Date().getTime();
        }


        download_link.download = title + ".xls";

        document.body.appendChild(download_link);
        download_link.click();
        document.body.removeChild(download_link);

    };

    vm.change_qte_by_type = function (type, commercial, retour_date_debut = null, retour_date_fin = null) {
        if (type != 'chargement') {
            vm.chargement['ch']['retour_hier'] = false;
        }
        if (type == 'retour') {
            _.each(vm.produits, (el, ind) => {
                _.extend(vm.chargement['ch'][el.id], vm.chargement['ch'][el.id], { quantite: 0, retour_hier_p: 0 });
            });
            //if(retour_date_debut && retour_date_fin){
            WS.getData('chargements/change_qte_by_type', { type, commercial, retour_date_debut, retour_date_fin }).then(
                (response) => {
                    if (response.data.length > 0) {

                        _.each(vm.produits, (p, index) => {
                            //if(p instanceof Object){

                            _.each(response.data, (el, ind) => {
                                if (el.produit_code == p.code) {
                                    _.extend(vm.chargement['ch'][p.id], vm.chargement['ch'][p.id], { quantite: el.quantite_retour });
                                }
                            });
                            //}
                        });
                    }
                    $scope.$apply();
                },
                (error) => {
                    console.log(error)
                }
            );
            //}

        } else {
            _.each(vm.produits, (p, index) => {
                _.extend(vm.chargement['ch'][p.id], vm.chargement['ch'][p.id], { quantite: 0, retour_hier_p: 0 });
            });
        }
    }

    //check for authorization_stock
    vm.check_auth_stock = function () {
        WS.getScache('societes')
            .then(function (response) {
                vm.societes = response.data[0];
                var contrat_code = vm.societes.contrat;
                /*console.log(response);
                console.log(contrat_code);*/
                $http({
                    method: 'GET',
                    url: `https://api.sav.priorityexpeditions.com/api/v1/license/${contrat_code}`,
                    cache: false
                }).then(function successCallback(response) {

                    if ($.isEmptyObject(response.data) == true) {
                        vm.errorCheckContrat = `Erreur de vérification de compte, veuillez contacter l'admin.`;
                    } else {
                        if (response.data.isactif == "1") {
                            vm.authorization_stock = response.data.authorization_stock;
                        }
                        else {
                            vm.expiration = true;
                            vm.errorCheckContrat = `Votre compte a été désactivée.`;
                        }
                    }
                }, function errorCallback(response) {

                });
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };


    vm.check_class_red_green = function (colisage, stk_quantite, id_prod) {
        //if with retourhier
        //console.log(colisage, id_prod, stk_quantite);
        if (vm.chargement.ch.retour_hier) {
            if (vm.chargement['ch']['colisage']) {
                if (
                    (vm.chargement['ch'][id_prod]['retour_hier_p']) +
                    (colisage * vm.chargement['ch'][id_prod]['quantite']) > stk_quantite || vm.chargement['ch'][id_prod]['quantite'] < 0
                ) {
                    return { red_clr: true };
                } else {
                    return { green_clr: true };
                }
            } else {
                if (
                    vm.chargement['ch'][id_prod]['retour_hier_p'] +
                    vm.chargement['ch'][id_prod]['quantite'] > stk_quantite || vm.chargement['ch'][id_prod]['quantite'] < 0
                ) {
                    return { red_clr: true };
                } else {
                    //console.log(vm.chargement['ch'][id_prod]['retour_hier_p'] ,  vm.chargement['ch'][id_prod]['quantite'])
                    return { green_clr: true };
                }
            }
        } else {
            //sans retour_hier
            if (vm.chargement['ch']['colisage']) {
                if (colisage * vm.chargement['ch'][id_prod]['quantite'] > stk_quantite || vm.chargement['ch'][id_prod]['quantite'] < 0) {
                    return { red_clr: true };
                } else {
                    return { green_clr: true }
                }
            } else {
                if (vm.chargement['ch'][id_prod]['quantite'] > stk_quantite || vm.chargement['ch'][id_prod]['quantite'] < 0) {
                    return { red_clr: true };
                } else {
                    return { green_clr: true }
                }
            }
        }

    };




    // vm.selectDepotWithUser = function () {
    //     WS.get('depots/selectDepotWithUser', vm.access)
    //         .then(function (response) {
    //             if (response.status == 200) {
    //                 vm.selectdepots = response.data;
    //                 $scope.$apply();
    //             } else { }
    //         })
    //         .then(null, function (error) {
    //             console.log(error);
    //         });
    // };

    vm.change_commercials_value = function (user_code) {
        console.log(user_code);
        if (user_code == "TOUT_CAMIONS") {
            vm.multi_camion = true;
            //vm.chargement['ch']['retour_hier'] = false;
            //vm.chargement['ch']['type'] = 'chargement';
            vm.commercial_selected = undefined;
        } else {
            vm.multi_camion = false;
            //vm.commercial_selected = _.find(vm.commercials, function(num){ return num.code == vm.chargement.ch.commercial; });
            WS.get('users/byCode/' + user_code)
                .then(function (response) {
                    vm.commercial_selected = response.data;
                    //console.log(vm.commercial_selected);
                    $scope.$apply();
                })
                .then(null, function (error) {
                    console.log(error);
                });
        }

        vm.findAllQuota();
    }



    vm.updateTotalSortie = (user_code, prod_id) => {

        console.log(user_code, prod_id);

        vm.allchargementsTotal = 0;
        vm.totalsortiecamion[prod_id] = 0;
        vm.chargement['inverse_multi_camion'][prod_id][user_code]['quantite'] = vm.chargement['multi_camion'][user_code][prod_id]['quantite'];

        _.each(vm.chargement.inverse_multi_camion[prod_id], (el, ind) => {
            console.log(typeof el);
            if (typeof el === 'object' && el != null) {
                if (typeof vm.totalsortiecamion[prod_id] === 'number') {
                    vm.totalsortiecamion[prod_id] += (el.quantite != undefined) ? el.quantite : 0;
                } else {
                    vm.totalsortiecamion[prod_id] = (el.quantite != undefined) ? el.quantite : 0;
                }
            }
        });
        vm.chargement['inverse_multi_camion'][prod_id]['total_chargement'] = vm.totalsortiecamion[prod_id];
        _.each(vm.produits, (prod, ind) => {
            if (typeof vm.totalsortiecamion[prod.id] === 'number') {
                if (typeof vm.allchargementsTotal === 'number') {
                    vm.allchargementsTotal += vm.totalsortiecamion[prod.id];
                }
                // else {
                //     vm.allchargementsTotal = vm.totalsortiecamion[prod.id];
                // }
            }
        });
    };


    vm.init_caisse_code = function () {
        if (vm.stat_caisse == "caisse_non") {
            vm.stat_caisse_code = '';
        }
    };


    vm.getUserByTourneeCode = function () {

        WS.getData('chargements/getUserByTourneeCode', { tournee_code: vm.chargement.ch.tournee_code })
            .then(function (response) {
                vm.infochargtype = response.data;
                //console.log(vm.infochargtype);
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.getTotalchargement_type = function () {
        WS.getData('chargements/getTotalchargement_type', { tournee_code: vm.chargement.ch.tournee_code, code_jour: vm.chargement.ch.code_jour })
            .then(function (response) {
                if (response.data.length > 0) {
                    _.each(vm.produits, (element, index) => {
                        _.each(response.data, (prod, indprod) => {
                            if (element.code == prod.produit_code) {
                                vm.produits[index].total_chargement = prod.sum_quantite;
                                vm.produits[index].qte_gajou = prod.qte_gajou;
                                vm.produits[index].retour_conforme = prod.retour_conforme;

                            }
                        });
                    });
                } else {
                    _.each(vm.produits, (element, index) => {
                        vm.produits[index].total_chargement = undefined;
                        vm.produits[index].qte_gajou = undefined;
                        vm.produits[index].retour_conforme = undefined;
                    });
                }

                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.export_chargement_type = function (tableId) {

        var uri = 'data:application/vnd.ms-excel;base64,',
            template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
            base64 = function (s) { return $window.btoa(unescape(encodeURIComponent(s))); },
            format = function (s, c) { return s.replace(/{(\w+)}/g, function (m, p) { return c[p]; }) };

        var table = $(tableId),
            ctx = { worksheet: 'WireWorkbenchDataExport', table: table.html() },
            href = uri + base64(format(template, ctx));

        //
        var download_link = document.createElement('a');
        download_link.href = href;
        var title = 'ChargementType_||_' + vm.chargement.ch.code_jour + '_||_' + vm.chargement.ch.tournee_code;

        download_link.download = title + ".xls";

        document.body.appendChild(download_link);
        download_link.click();
        document.body.removeChild(download_link);

    };

    vm.total_Casier_chargement_type = function () {

        vm.total_casier = _.reduce(vm.chargement['ch']['produits'], function (memo, num) {
            //console.log(memo, num)
            if (num.quantite != undefined && num.quantite != NaN) {
                return memo + Math.round(Number(num.quantite) / num.colisage);
            } else {
                return memo;
            }
        }, 0);
    };


    vm.total_Casier_chargement = function () {
        /**
         * total caisse
         */
        vm.total_casier_charg = 0;
        vm.total_casier_charg = _.reduce(vm.chargement['ch'], function (value, prod) {
            if (typeof prod === 'object') {
                // console.log(value, prod);
                /* if(vm.chargement.ch.retour_hier){
                     return value + ((Math.round(Number(prod.quantite) + Number(prod.retour_hier_p)) / prod.colisage)); 
                     
                 }else{
                 */
                if (prod.hors_box == 0) {
                    if (prod.quantite != undefined && prod.quantite != NaN) {
                        if (vm.chargement.ch.colisage == false) {
                            return value + Math.round(Number(prod.quantite) / prod.colisage);
                        } else {
                            return value + Math.round(Number(prod.quantite));
                        }
                    } else {
                        return value;
                    }
                } else {
                    return value;
                }

                // }
            } else {
                return value;
            }
        }, 0);


        //////////// append vm.total_casier_charg to input caisse //////////////   /\ 
        //                                                                         /--\ 
        //                                                                         /____\
        //                                                                         /_//\\_\                      
        var caisse = _.find(vm.produits, function (item) {                          //  \\
            return (item.code == 'CAISSE' || item.code.toLowerCase() == 'ga');     //    \\
        });                                                                       //      \\
        if (caisse) {                                                            //        \\
            vm.chargement.ch[caisse.id].quantite = vm.total_casier_charg;       //          \\
        }                                                                      //   DEVAPP   \\
        ////////////////////////////////////////////////////////////////______//==============\\______

        /**
         * montant total
         */
        vm.total_montant_charg = 0;
        vm.total_montant_charg = _.reduce(vm.chargement['ch'], function (value, prod) {
            if (typeof prod === 'object') {
                // console.log(value, prod);

                if (vm.chargement.ch.retour_hier) {
                    return value + ((Number(prod.quantite) + Number(prod.retour_hier_p)) * prod.prix_ttc);

                } else {
                    if (prod.quantite != undefined && prod.quantite != NaN) {
                        return value + Number(prod.quantite) * prod.prix_ttc;
                    } else {
                        return value;
                    }
                }
            } else {
                return value;
            }
        }, 0);
        //vm.total_montant_charg.toFixed(3);
    };

    vm.reinisialiserQTE = function () {
        _.each(vm.produits, (el, ind) => {
            _.extend(vm.chargement['ch'][el.id], vm.chargement['ch'][el.id], { quantite: 0 });
        });
    }


    vm.stat_table_vente = function () {
        var data = {
            user_code: vm.stat_user_code,
            date_debut: vm.stat_date_debut,
            date_fin: vm.stat_date_fin
        }
        WS.getData('chargements/stat_table_vente', data)
            .then(function (response) {
                vm.stat_vente = response.data;

                vm.sum_qte_total = 0;
                vm.sum_ca_total = 0;
                vm.sum_qte_total_cl = 0;
                vm.sum_ca_total_cl = 0;
                // vm.sum_qte_total_out_cl = 0;
                // vm.sum_ca_total_out_cl = 0;

                _.each(vm.stat_vente, (element, index) => {
                    if (element.hors_box == 0) {
                        vm.sum_qte_total += element.qte_total;
                        vm.sum_ca_total += element.ca_total;

                        vm.sum_qte_total_cl += element.qte_total_cl;
                        vm.sum_ca_total_cl += element.ca_total_cl;
                    }
                    // vm.sum_qte_total_out_cl += element.qte_total_out_cl;
                    // vm.sum_ca_total_out_cl += element.ca_total_out_cl;
                });
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };



    vm.exportVenteReel = function () {
        var user_code = (vm.stat_user_code) ? vm.stat_user_code : '';
        var filename = 'vente-reel__' + user_code + '__' + vm.stat_date_debut + '__' + vm.stat_date_fin;

        var file = {
            sheetid: filename,
            headers: true,
            // caption: {
            //     title: 'Rapport-Tournee-' + moment().format("DD-MM-YYYY HH:mm:ss"),
            //     style: 'font-size: 50px; color:blue;'
            // },
            column: {
                style: 'font-size:20px;background:#d1d1d1;'
            },
            columns: [

                { columnid: 'code', title: 'Code' },
                { columnid: 'qte_total', title: 'Quantite' },
                { columnid: 'ca_total', title: 'Ca' }

            ],
            row: {
                style: function (sheet, row, rowidx) {
                    return 'text-align:center;background:' + (rowidx % 2 ? '#fff' : '#f1f1f1') + ';height:100px!important;';
                }
            }
        };
        alasql('SELECT *  INTO XLS("' + filename + '.xls",?) FROM ?', [file, vm.stat_vente]);
    };

    vm.venteFactureCL = function () {
        var user_code = (vm.stat_user_code) ? vm.stat_user_code : '';
        var filename = 'vente-facturé-cl__' + user_code + '__' + vm.stat_date_debut + '__' + vm.stat_date_fin;

        var file = {
            sheetid: filename,
            headers: true,
            // caption: {
            //     title: 'Rapport-Tournee-' + moment().format("DD-MM-YYYY HH:mm:ss"),
            //     style: 'font-size: 50px; color:blue;'
            // },
            column: {
                style: 'font-size:20px;background:#d1d1d1;'
            },
            columns: [

                { columnid: 'code', title: 'Code' },
                { columnid: 'qte_total_cl', title: 'Quantite_cl' },
                { columnid: 'ca_total_cl', title: 'Ca_cl' }

            ],
            row: {
                style: function (sheet, row, rowidx) {
                    return 'text-align:center;background:' + (rowidx % 2 ? '#fff' : '#f1f1f1') + ';height:100px!important;';
                }
            }
        };
        alasql('SELECT *  INTO XLS("' + filename + '.xls",?) FROM ?', [file, vm.stat_vente]);
    };


    vm.venteReelCPassager = function () {
        var user_code = (vm.stat_user_code) ? vm.stat_user_code : '';
        var filename = 'vente-reel-client-passager__' + user_code + '__' + vm.stat_date_debut + '__' + vm.stat_date_fin;

        var file = {
            sheetid: filename,
            headers: true,
            // caption: {
            //     title: 'Rapport-Tournee-' + moment().format("DD-MM-YYYY HH:mm:ss"),
            //     style: 'font-size: 50px; color:blue;'
            // },
            column: {
                style: 'font-size:20px;background:#d1d1d1;'
            },
            columns: [

                { columnid: 'code', title: 'Code' },
                { columnid: 'qte', title: 'Quantite' },
                { columnid: 'ca', title: 'Ca' }

            ],
            row: {
                style: function (sheet, row, rowidx) {
                    return 'text-align:center;background:' + (rowidx % 2 ? '#fff' : '#f1f1f1') + ';height:100px!important;';
                }
            }
        };
        alasql('SELECT code as code,(qte_total - qte_total_cl) as qte,(ca_total - ca_total_cl) as ca   INTO XLS("' + filename + '.xls",?) FROM ?', [file, vm.stat_vente]);
    };
    // Vente Facturé CL	Vente reel Client Passager

    vm.log_test = (verif_import) => {
        console.log(verif_import);
    };

}
export default {
    name: 'quotaController',
    fn: quotaController
};